import React from 'react'
import Layout from '../components/layout'
import { graphql, useStaticQuery } from 'gatsby'

export default function NotFoundPage ({ path }) {
  const { wpgraphql } = useStaticQuery(
    graphql`
      query NotFoundQuery {
        wpgraphql {
          options {
            general {
              image404 {
                mediaItemUrl
                altText
              }
            }
          }
        }
      }
    `
  )

  const { mediaItemUrl, altText } = wpgraphql.options.general.image404

  const page = {
    seo: {
      title: 'Page not found | Primo Toys',
      metaDesc: '',
      noIndex: true
    },
    slug: '404'
  }

  return (
    <Layout page={page} template='page' path={'/404/'}>
      <section className='u-sectionPadded'>
        <section className='NotFound'>
          <div>
            <img src={mediaItemUrl} alt={altText || '404'} />
          </div>
          <div>
            <h1>
              <span className='NotFound-status'>404</span>
              not found!
            </h1>
            <a className='Button' href='/'>home page</a>
          </div>
        </section>
      </section>
    </Layout>
  )
}
